/* eslint-disable react/no-danger */
import React, { FC, useEffect, useState, useMemo } from "react";
import { graphql } from "gatsby";
import { observer } from "mobx-react";
import styled from "styled-components";
import { ContentPageLayout } from "@components/Layout";
import { Table } from "@components/Table";
import { FormattedMessage as M } from "gatsby-plugin-intl";
import { BlueBox } from "@components/BlueBox";
import { NaviButtons } from "@components//NaviButtons";
import { TextSection } from "@components/TextSection";
import { FormattedDate as Date } from "gatsby-plugin-intl";
import { rem } from "polished";
import { PAGE_NAV_ITEMS } from "@constants";
import { useStores } from "@stores";
import { getFaultReportState, getFaultReportStateColor } from "@utils";
import { Button, Spinner } from "@components/Primitives";
import { colors } from "../../styles/theme";
import { FaultReportModal } from "@components/Modal/hocs/FaultReportModal";
import { Tooltip } from "@components/Tooltip";

const Indicator = styled.span`
  height: ${rem("10px")};
  width: ${rem("10px")};
  border-radius: ${rem("10px")};
  display: block;
`;
const Status = styled.span`
  display: flex;
  align-items: center;
`;

const StyledBlueBox = styled(BlueBox)`
  margin-top: 1rem;
`;

const StatusValue = styled.div`
  margin-right: 0.5rem;
  margin-left: 0.5rem;
`;

interface MyInformationFaultReportsPageProps {
  data: IMarkdownData;
}

const ButtonContainer = styled.div`
  margin-top: -1.3rem;
  margin-bottom: 0.5rem;
`;

const MyInformationFaultReportsPage: FC<MyInformationFaultReportsPageProps> =
  observer(
    ({
      data: {
        markdownRemark: {
          frontmatter: { blocks: texts },
        },
      },
    }) => {
      const {
        agreementStore: { selectedAgreementId },
        faultReportStore: { faultReports, getFaultReports, state },
      } = useStores();

      const [selectedReport, setSelectedReport] = useState<
        IFaultReport | undefined
      >(undefined);

      useEffect(() => {
        if (selectedAgreementId) {
          getFaultReports(selectedAgreementId);
        }
      }, [getFaultReports, selectedAgreementId]);

      const isLoading = state === "Loading";

      const StatusCell = ({ value }: { value: string }) => {
        return (
          <Status>
            <Indicator
              style={{
                backgroundColor:
                  colors[
                    getFaultReportStateColor(value as IFaultReport["state"])
                  ],
              }}
            />
            <StatusValue>{value}</StatusValue>
            <Tooltip
              identifier={value}
              localeId={`pages.myInformation.faultReportsPage.info.${getFaultReportState(
                value as IFaultReport["state"]
              )}`}
            />
          </Status>
        );
      };

      const onOpenReportClick = (report: IFaultReport) => {
        setSelectedReport(report);
       // console.log(report)
      };

      const onModalClose = () => {
        setSelectedReport(undefined);
      };

      const columns = useMemo(() => {
        return [
          {
            Header: "notice",
            accessor: "title",
          },
          {
            Header: "date",
            accessor: "report_date",
            Cell: ({ cell }: ICell) =>
              cell.value ? <Date value={cell.value} /> : "",
          },
          {
            Header: "status",
            accessor: "state",
            Cell: ({ cell }: ICell) =>
              cell.value ? <StatusCell value={cell.value} /> : "",
          },
          {
            Header: "",
            accessor: "id",
            customRender: true,
            Cell: ({
              row: { original },
            }: {
              row: { original: IFaultReport };
            }) => {
              if (!original.reported_by) return null;
              return (
                <ButtonContainer>
                  <Button onClick={() => onOpenReportClick(original)}>
                    <M id="common.open" />
                  </Button>
                </ButtonContainer>
              );
            },
          },
        ];
      }, []);

      return (
        <ContentPageLayout wideContent>
          <TextSection
            title={<M id='fields.label.faultReports.title'/>}
            afterTitle={<NaviButtons items={PAGE_NAV_ITEMS.faultReports} />}
            text={<M id='fields.label.faultReports.text'/>}
          />
        <StyledBlueBox>
          <M id="pages.faultReportForm.emailInformation" />
        </StyledBlueBox>
          {isLoading && <Spinner color="mainBlue" />}
          {faultReports && !!faultReports.length && (
            <Table
              id="fault-reports"
              columns={columns}
              // @ts-ignore
              data={faultReports}
            />
          )}

          <FaultReportModal
            faultReport={selectedReport}
            onModalClose={onModalClose}
          />
        </ContentPageLayout>
      );
    }
  );

export const pageQuery = graphql`
  query MyInformationFaultReportsQuery {
    markdownRemark(frontmatter: { path: { eq: "/me/fault-reports/" } }) {
      frontmatter {
        title
        path
        blocks {
          text
          title
        }
      }
    }
  }
`;

export default MyInformationFaultReportsPage;

/* eslint-disable @typescript-eslint/interface-name-prefix */
/* eslint-disable react/no-danger */
import React, { FC, ReactNode } from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { rem } from "polished";
import { FormattedMessage as M } from "gatsby-plugin-intl";

interface IToolTip {
  identifier: string;
  localeId: string;
  infoButtonContent?: ReactNode
}

interface Props extends IToolTip {}

const InfoButton = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  height: ${rem("20px")};
  min-width: ${rem("20px")};
  border-radius: ${rem("20px")};
  font-size: ${(props) => props.theme.typography.fontSizes.xs};
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.mainBlue};
  cursor: pointer;
`;

const DEFAULT_INFO_BUTTON_CONTENT = "?";

const Tooltip: FC<Props> = ({ identifier, localeId, infoButtonContent }) => {
  return (
    <>
      <InfoButton data-tip data-for={identifier}>
        {infoButtonContent ? infoButtonContent : DEFAULT_INFO_BUTTON_CONTENT}
      </InfoButton>
      <ReactTooltip
        id={identifier}
        place="top"
        effect="solid"
        backgroundColor="#0070BA"
      >
        <M id={localeId} />
      </ReactTooltip>
    </>
  );
};

export { Tooltip };

import React, { FC } from "react";
import { colors } from "../../../styles/theme";
import { Modal } from "..";
import { useIntl, FormattedMessage as M } from "gatsby-plugin-intl";
import { Text, Box } from "@components/Primitives";
import styled from "styled-components";

interface FaultReportModalProps {
  faultReport?: IFaultReport;
  onModalClose: any;
}

const Content = styled.div`
  max-height: calc(90vh - 100px);
  overflow-y: auto;
`;

const FaultReportModal: FC<FaultReportModalProps> = ({
  faultReport,
  onModalClose,
}) => {
  if (!faultReport) return null;

  const intl = useIntl();
  return (
    <Modal
      onCloseClick={onModalClose}
      title={faultReport.title}
      style={{
        backgroundColor: colors.lightBlue,
        border: "2px solid",
        borderColor: colors.mainBlue,
        maxWidth: 800,
      }}
    >
      <Content>
        <Box mb="2">
          <Text fontWeight="600">
            <M id="pages.myInformation.faultReportsPage.modal.description" />
          </Text>
          {faultReport.description}
        </Box>

        <Box mb="2">
          <Text fontWeight="600">
            <M id="pages.myInformation.faultReportsPage.modal.contactDetails" />
          </Text>
          {faultReport.contact_details}
        </Box>

        <Box>
          <Text fontWeight="600">
            <M id="pages.myInformation.faultReportsPage.modal.entry" />
          </Text>
          <span>
            <M
              id="pages.myInformation.faultReportsPage.modal.pets"
              values={{ value: faultReport.pets }}
            />
          </span>
          <br />
          <span>
            <M
              id="pages.myInformation.faultReportsPage.modal.maintenanceEntry"
              values={{ value: faultReport.entry }}
            />
          </span>
          <br />
          <span>
            <M
              id="pages.myInformation.faultReportsPage.modal.sharedSpace"
              values={{ value: faultReport.common_areas
                ? intl.formatMessage({ id: "fields.label.yes" })
                : intl.formatMessage({ id: "fields.label.no" }),
              }}
            />
          </span>
        </Box>
      </Content>
    </Modal>
  );
};

export { FaultReportModal };
